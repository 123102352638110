import { ReactNode, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"

import Header from "components/Header"
import { updateProfile } from "slice/user/action"
import { useAppDispatch, useAppSelector } from "state/store"
import classNames from "classnames"
import { useLocation } from "react-router-dom"
import { getNotifications } from "../services/api/notification"
import { setNotifications } from "../slice/notification"
import { getMe } from "../services/api/users"
import { ACCESS_TOKEN } from "../constants/Authentication"

const USER_PAGE_ROUTES = [
  "/my-profile",
  "/my-orders",
  "/my-transactions",
  "/saved-products",
  "/settings",
]

const AUTH_PAGE_ROUTES = [
  "/sign-in",
  "/sign-up",
  "/forgot-password",
  "/verify-phone-number",
]

interface IMainLayoutProps {
  children: ReactNode
}

const MainLayout = ({ children }: IMainLayoutProps) => {
  const { i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const location = useLocation()

  const isLoading = useAppSelector((state) => state.common.isLoading)
  const currentUser = useAppSelector((state) => state.user.currentUser)

  useEffect(() => {
    ;(async () => {
      try {
        const accessToken = localStorage.getItem(ACCESS_TOKEN)

        if (accessToken) {
          const res = await getMe()
          if (res.data?.language) {
            await i18n.changeLanguage(res.data?.language)
          }
        }
      } catch (error) {
      }
    })()
  }, [currentUser?.id, dispatch, i18n])

  useEffect(() => {
    ;(async () => {
      try {
        const res = await getNotifications()
        if (res.data) {
          dispatch(setNotifications(res.data.notifications))
        }
      } catch (error) {
      }
    })()
  }, [dispatch])

  useEffect(() => {
    if (i18n.language && currentUser && !currentUser?.language) {
      dispatch(updateProfile({ ...currentUser, language: i18n.language }))
    }
  }, [currentUser, dispatch, i18n.language])

  const fullScreen = useMemo(() => {
    return location.pathname.includes("/map")
  }, [location.pathname])

  return (
    <>
      {isLoading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-primary/90 z-[101] flex items-center justify-center">
          <div className="loader" />
        </div>
      )}
      <div className="h-screen flex flex-col">
        <Header />
        <div
          className={classNames(`flex-1 overflow-x-hidden ${fullScreen ? "" : "pt-4 px-6 2xl:px-20"}`, {
            "pb-0": USER_PAGE_ROUTES.includes(location.pathname),
            "!p-0": AUTH_PAGE_ROUTES.includes(location.pathname),
          })}
        >
          {children}
        </div>
      </div>
    </>
  )
}

export default MainLayout
