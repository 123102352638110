import axios from "axios"
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../constants/Authentication"

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1`,
  timeout: 60000,
})

let isRefreshingToken = false
let requestToRetry: any = []

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(ACCESS_TOKEN)
    if (token && config.headers) {
      config.headers["Authorization"] = `Bearer ${token}`
    }
    // config.headers['Content-Type'] = 'application/json';
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response) => response,
  /**
   * Handle response error
   */
  async (error) => {
    const { config } = error

    /**
     * If the error is 401 (Unauthorized), it means the access token is invalid.
     *
     * We will try to refresh the token and retry the request.
     *
     * @tutorial
     * Get the refresh token from local storage.
     *
     * 1.   If the refresh token is invalid:
     * 1.1. We will redirect the user to the login page.
     *
     * 2.   If the refresh token is valid:
     * 2.1. We will get a new access token and retry the request.
     * 2.2. When the new access token is refreshing, we will add all the pending requests that are waiting for the new token. (add to the requestToRetry array)
     *
     * 2.3. We retry all the requests that are waiting for the new token in the requestToRetry array.
     * 2.4. Set the new access token to local storage.
     */
    if (error.response.status === 401) {
      if (!isRefreshingToken) {
        try {
          /**
           * Get the refresh token from local storage.
           */
          const storedRefreshToken = localStorage.getItem(REFRESH_TOKEN)

          /**
           * 1. If the refresh token is invalid:
           * 1.1. We will redirect the user to the login page.
           */
          if (!storedRefreshToken) {
            return Promise.reject(error)
          }

          /**
           * 2. If the refresh token is valid:
           */
          isRefreshingToken = true

          /**
           * 2.1. We will get a new access token and retry the request.
           */
          const response = await instance.post("/auth/refresh-token", {
            refreshToken: storedRefreshToken,
          })

          /**
           * 2.3. We retry all the requests that are waiting for the new token in the requestToRetry array.
           */
          const { accessToken, refreshToken } = response.data

          requestToRetry.forEach((callback: any) => {
            callback(accessToken)
          })

          /**
           * 2.4. Set the new access token to local storage.
           */
          setToken(accessToken, refreshToken)
        } catch (error) {
          clearToken()
          requestToRetry.forEach((cb: any) => cb(null))
        } finally {
          isRefreshingToken = false
          requestToRetry = []
        }
      }

      /**
       * 2.2. When the new access token is refreshing, we will call all the pending requests that are waiting for the new token. (add to the requestToRetry array)
       */
      return new Promise((resolve, reject) => {
        requestToRetry.push((token: string) => {
          if (token) {
            config.headers["Authorization"] = `Bearer ${token}}`
            resolve(instance(config))
          }

          reject(error)
        })
      })
    }

    return Promise.reject(error)
  },
)
//
// export const setAuthToken = (token: string) => {
//   if (token) {
//     //applying token
//     instance.defaults.headers.common["Authorization"] = token
//   } else {
//     //deleting the token from header
//     delete instance.defaults.headers.common["Authorization"]
//   }
// }


/**
 * Clear the access token and refresh token from local storage.
 */
export const clearToken = () => {
  localStorage.removeItem(ACCESS_TOKEN)
  localStorage.removeItem(REFRESH_TOKEN)
}

/**
 * Set the access token and refresh token to local storage.
 */
export const setToken = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken)
  localStorage.setItem(REFRESH_TOKEN, refreshToken)
}

export default instance
